body {
  margin: 1em auto;
  padding: 0 1em;
  max-width: 60em;
  font-family: Cochin, Palatino, serif;
  font-size: 1.2em;
  line-height: 2;
  text-align: center;
  background: black;
  color: white;
}

html,
body,
#root,
#reach-root {
  min-height: 100%;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

input,
fieldset,
hr,
textarea,
button {
  border-style: solid;
  border-color: currentColor;
  border-width: 1px;
  border-radius: 0;
}

button {
  cursor: pointer;
}

.invisible-button {
  border: none;
  padding: 0;
}

.invisible-button * {
  display: block;
}

textarea {
  resize: vertical;
}

hr.flourish {
  width: 80%;
  border: none;
  border-top: 1px solid currentColor;
  height: 0.5em;
  overflow: visible;
}

hr.flourish:after {
  content: '/ /';
  display: inline-block;
  position: relative;
  top: -1em;
}

.futura {
  font-family: Futura, Trebuchet MS, sans-serif;
  text-transform: uppercase;
}

.subtitle {
  text-transform: capitalize;
  font-size: 3em;
}

.medium {
  font-size: 1.9em;
}

.medium-small {
  font-size: 1.5em;
}

.script {
  font-family: 'Parisienne', 'Vladimir Script', cursive;
  line-height: 1;
}

img {
  max-width: 100%;
}

.border {
  /* filter: grayscale(1); */
  height: 50vh;
  border-style: solid;
  border-width: 1em;
  border-image: url('./border.svg');
  border-image-slice: 40%;
}

input,
textarea,
button {
  color: inherit;
  background: inherit;
  font: inherit;
  padding: 1em;
  line-height: 2;
}

input[type='submit'] {
  text-transform: inherit;
}

.input-group {
  margin: 1em auto;
  display: flex;
  flex-direction: column;
}

p {
  margin: 0.25em 0;
}

.inline-radio {
  padding: 0;
}

.inline-radio .inner {
  display: flex;
  justify-content: space-between;
}

.inline-radio .inner div {
  margin: 0 auto;
  width: 100%;
}

.inline-radio .inner label {
  display: block;
  padding: 1em;
  width: 100%;
  height: 100%;
}

.visibly-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.inline-radio input:checked ~ label {
  color: black;
  background-color: white;
}

.inline-radio hr {
  margin: 0;
}

.language {
  padding: 0.2em 1em;
}

@media (min-width: 940px) {
  .language {
    position: absolute;
    left: 1em;
    top: 2em;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 2em;
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.margin-v-large {
  margin: 2em auto;
}

.darkmode {
  filter: grayscale(1) invert();
}

address {
  font-style: normal;
}

.narrow-center {
  width: max-content;
  margin: 0 auto
}

.subtle-list {
  text-align: left;
  margin: 0;
  list-style: none;
  padding: 0;
}

